import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CategoriesService from "./services/CategoriesService";
import ProductsService from "./services/ProductsService";
import thumb from '../images/coffee.png';
import CartService from "./services/CartService";
import Categories from "./Categories";

const STATIC_URL = process.env.REACT_APP_API_BASE_STATIC_URL;

const Catalog = ({ refreshCart, setLoading, isProductInCart }) => {

    const [products, setProducts] = useState("");
    const [title, setTitle] = useState("");

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const { categoryId } = useParams();

    const getCatalog = () => {
        setTitle("Каталог");
        setLoading(true);
        ProductsService.getProducts().then(
            (response) => {
                setProducts(response);
                //console.log(response);
                setLoading(false);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (categoryId) {
            getCategory(categoryId);
        } else {
            getCatalog();
        }
        refreshCart();


    }, []);

    const getCategory = (category_id) => {

        setLoading(true);
        ProductsService.getProducts(category_id).then(
            (response) => {
                setProducts(response);
                setLoading(false);
            }
        );

        CategoriesService.getCategory(category_id).then(
            (response) => {
                if (response) {
                    setTitle(response.name);
                }
            }
        );
    }

    const addToCart = (e, product_id) => {
        if (isProductInCart(product_id)) return false;

        CartService.addToCart(product_id).then(
            (response) => {
                e.target.textContent = "✓ В корзине";
                e.target.classList.add("btn-success");
                e.target.classList.remove("btn-primary");
                refreshCart();

            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setError(_content);
            }
        );
    }

    const productItems = !products ? "" : products.map((product) => {

        let productCategories = product.categories.map((category) => {
            // return <a key={category.id} href={"/catalog/" + category.id}>{category.name}</a>
            return <span key={category.id}>{category.name}</span>
        });
        let productSpecs = product.specs.map((spec) => <li key={product.id + '_sp' + spec.id}>{spec.key}: {spec.value}</li>);

        let priceMargin = product.margins.length == 0 ? 0 : Math.min(...product.margins.map((m) => m.margin_value));

        return <div className="col-lg-6 mb-4" key={product.id}><div className="card mb-3 product">
            <div className="row g-0 align-items-stretch">
                <div className="col-md-4 align-self-center">
                    <img src={product.images.length > 0 ? STATIC_URL + product.images[0] : thumb} className="img-fluid rounded-start" alt={product.name} />
                </div>
                <div className="col-md-8">
                    <div className="card-body">

                        <h5 className="card-title">{product.name}</h5>
                        <p className="card-text product-categories mb-0"><small className="text-muted">{productCategories}</small></p>
                        <p className="card-text">{product.short_description}</p>
                        <ul>{productSpecs}</ul>
                        <p className="card-price">{priceMargin == 0 ? product.price + " руб." : "от " + (product.price + priceMargin) + " до " + product.price + " руб."}</p>

                        <button onClick={(e) => addToCart(e, product.id)} className={isProductInCart(product.id) ? "btn btn-success btn-to-cart" : "btn btn-primary btn-to-cart"}>
                            {isProductInCart(product.id) ? "✓ В корзине" : "В корзину"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    }
    );

    return (
        <div>
            {error && (<div className="alert alert-danger">
                {error}
            </div>)}
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="mb-0">{title}</h1>
                {<button className="btn btn-secondary mb-4" onClick={() => navigate(-1)}>Назад</button>}
            </div>
            <p className="badge text-bg-success fs-6 mb-2">Актуальные цены</p>
            <Categories urlPrefix="catalog" />
            <div className="row mt-4 align-items-stretch">
                {productItems}
            </div>

        </div>
    );
};

export default Catalog;